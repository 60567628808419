
.return_policy_wrapper
{
    width: 100%;
    height: auto;
}

.return_policy_parent_section
{
    width: 100%;
    height: auto;
}

.return_policy_content_section
{
    width: 100%;
    height: auto;
}

.return_policy_content_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.return_policy_content_section ul li
{
    font-size: 15px;
    color: #909295;
    line-height: 27px;
}

.leave_your_comment_section
{
    width: 60%;
    height: auto;
}

.leave_your_comment_section h3
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}