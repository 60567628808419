
.contact_us_form_section
{
    width: 100%;
    height: auto;
}

.form_input_field
{
    width: 100%;
    height: auto;
    margin-top: 20px!important;
}

.submit_btn
{
    margin: 15px 0;
    border: 0;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-transform: uppercase;
    padding: 10px 40px;
    background: #333;
}