
.login_wrapper
{
    width: 100%;
    height: auto;
}

.login_parent_section
{
    width: 100%;
    height: auto;
}

.login_content_section
{
    width: 100%;
    height: auto;
}

.login_content_area
{
    max-width: 700px;
    height: auto;
    background-color: #f5f5f5;
    margin: 0 auto;
    padding: 40px;
    border-radius: 5px;
}

.login_content_area h2
{
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}

.sign_in_btn
{
    border: 0;
    background-color: #4ea4cc;
    color: white;
    padding: 8px 19px;
    border-radius: 4px;
    margin: 15px 0;
    margin-right: 20px;
    font-weight: 600;
}

.remeber_me_Label
{
    margin-left: 7px;
    font-weight: bold;
    font-size: 15px;
}

.login_content_area a
{
    text-decoration: none;
    font-size: 15px;
    /* color: #4ea4cc; */
    margin-left: 5px;
}

.createAcc-btn
{
    display: block;
    width: 100%;
    margin: 0 auto;
    background: #4ea4cc;
    text-align: center;
    padding: 10px;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
}

.createAcc-btn:hover
{
    color: white;
}