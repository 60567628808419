.card
{
    width: 90%;
    height: 100%!important;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(0,0,0,.18);
}

.carousel_img_section
{
    width: 80%;
    height: 260px;
    margin: 0 auto;
}

.carousel_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carousel_content_section
{
    width: 100%;
    height: 120px;
    background-color: #efefef;
    padding: 20px;
}

.card_hover_title
{
    position: relative;
    z-index: 1000;
    color: white!important;
    font-size: 15px;
    display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
    cursor: pointer;
}

.card_hover_price
{
    position: relative;
    z-index: 1000;
}

.card_title
{
    font-size: 1rem;
    color: black;
	text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.carousel_content_section p
{
    font-size: 17px;
    text-align: center;
    color: #2ea2cc;
    font-weight: bold;
}

.cards_hover_section
{
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .35);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.shopping_cart_btn
{
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping_cart_btn:hover
{
    background-color: #2ea2cc;
    cursor: pointer;
}

.shopping_cart_btn:hover .cards_hover_icon
{
    color: white;
}

.favourite_btn
{
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.favourite_btn:hover
{
    background-color: #2ea2cc;
    cursor: pointer;
}

.favourite_btn:hover .cards_hover_icon
{
    color: white;
}

.list_view_section
{
    width: 100%;
    height: auto;
}

.list_view_section p
{
    font-size: 15px;
    color: #949494;
    text-align: left;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.listview_card
{
    width: 100%;
    display: flex;
}

.listview_content_section
{
    height: auto;
}

.product_detail_btn
{
    border: 1px solid #333;
    background-color: transparent;
    color: #333;
    padding: 5px 15px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
}

.product_detail_btn:hover
{
    background-color: #2ea2cc;
    color: #fff;
    border: 1px solid #2ea2cc;
}
