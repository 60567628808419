*
{
    box-sizing: border-box;
}
p,h1,h2,h3,h4,h5,h6
{
    margin: 0;
}
.cycle-card
{
    width: 100%;
    max-width: 300px;
    height: auto;
    background-color: lightblue;
    border: thin solid #dedede;
    border-radius: 8px;
    margin: 1rem;
}
.image-area
{
    width: 100%;
    height: 180px;
    background-color: pink;

}
.image-area img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.detail-area
{
    width: 100%;
    height: auto;
    background-color: yellow;
    padding: 20px;
}