.signup_wrapper
{
    width: 100%;
    height: auto;
}

.signup_parent_section
{
    width: 100%;
    height: auto;
}

.signup_content_section
{
    width: 100%;
    height: auto;
}

.signup_content_section h2
{
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}

.signup_btn
{
    border: 0;
    background-color: #2ea2cc;
    color: white;
    padding: 8px 25px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    display: block;
}

.signup_content_section .createAccLabel
{
    margin: 20px 0;
    margin-left: 7px;
    font-size: 15px;
}

.signup_content_section .chkbox
{
    padding: 0!important;
    margin: 20px 0;
}

.login_here
{
    margin-left: 8px;
}