.header_wrapper
{
    width: 100%;
    height: auto;
    background-color: black;
}

.header_section
{
    width: 100%;
    height: auto;
}

.header_logo_section
{
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
}

.header_logo_section img
{
    width: 90px;
    object-fit: cover;
    margin-bottom: 13px;
}

.header_upper_section
{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    padding: 6px 40px;
    gap: 20px;
    flex-direction: row-reverse;
}

.social_icons
{
    width: fit-content;
    height: auto;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 13px;
}

.soc_icon
{
    font-size: 16px;
    color: black;
}

.contact_section
{
    width: fit-content;
    height: auto;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 7px;
}

.contact_section span
{
    font-size: 14px;
    color: black;
}

.contact_section span a
{
    color: black;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
}

.contact_section span a:hover
{
    color: #2ea2cc;
}

.contact_icon
{
    font-size: 16px;
    color: black;
}

.search_section
{
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search_area
{
    width: 100%;
    height: auto;
    padding: 3px 3px 6px 7px;
    border-radius: 10px;
    border: 1px solid #9c9c9c;
    margin-top: 3px;
}

.search_input
{
    width: 85%;
    background: transparent;
    border: 0;
    color: #999999;
    font-size: 14px;
}

.search_input:focus-visible
{
    outline: transparent;
}

.search_icon
{
    font-size: 17px;
    color: #f3efef;
    margin-bottom: 3px;
}

.search_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.soc_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.setting_section
{
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}

.setting_icon
{
    font-size: 28px;
    color: #f3efef;
}

.setting_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.shopping_cart_section
{
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
}

.shopping_cart_icon
{
    font-size: 28px;
    color: #f3efef;
}

.shopping_cart_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.count
{
    position: absolute;
    top: 12px;
    right: 34px;
    height: 25px;
    width: 25px;
    font-size: 12px;
    line-height: 27px;
    text-align: center;
    background: #2ea2cc;
    color: #ffffff;
    border-radius: 50%;
}

.navbar_section
{
    width: 100%;
    height: auto;
    /* background-color: #f3efef; */
    display: flex;
    align-items: center;
    position: relative;
}

.navbar_listarea
{
    width: 100%;
    height: auto;
    
}

.navbar_listarea ul
{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

}

.navbar_listarea ul li 
{
    list-style: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 27px 0;
}

.navbar_listarea ul li a
{
    list-style: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 27px 0;
    text-decoration: none;
}

.navbar_listarea ul li a:hover
{
    color: #2ea2cc;
}

.navbar_listarea ul li:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.red
{
    color: red!important;
}

.ml0
{
    margin-left: 0!important;
}

