
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.hero_section 
{
    width: 100%;
    height: 100vh;
}

.inner-sec-container
{
    max-width: 1150px;
    height: auto;
    margin: 0 auto;
}

.carousel-root 
{
    width: 100%;
    height: 100%;
    position: relative;
}

.carousel 
{
    width: 100%;

}

.carousel_item 
{
    width: 100%;
    height: 100%;
}

.slider_heading 
{
    font-size: 22px;
    color: black;
    position: absolute;
    top: 40%;
    left: 20%;
}

.slider_img 
{
    width: 100%;
    object-fit: cover;
    height: 100vh;
}

.hero_section .carousel .thumbs-wrapper 
{
    display: none !important;
}

.hero_section .carousel .control-dots 
{
    display: none !important;
}


.Home_Page_Content_Section 
{
    width: 100%;
    height: auto;
}

.services_section 
{
    width: 100%;
    height: auto;
    padding: 40px 0;

}

.services-area 
{
    width: 100%;
    height: 190px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .0) 0px 0px 0px, rgba(0, 0, 0, .20) 0px 1px 4px 2px, rgba(0, 0, 0, .14) 0px -3px 0px inset;
}

.services-icon-section 
{
    width: 100%;
    height: 62%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services_icon 
{
    font-size: 60px;
    color: #2ea2cc;
}

.services_heading 
{
    font-size: 17px;
    color: black;
    text-align: center;
    margin: 10px 0;
    font-weight: 600;
    text-decoration: none;
}

.services_heading:hover 
{
    color: #2ea2cc;
    cursor: pointer;
}

.ml0
{
    margin: 0 !important;
}

.featured_products 
{
    width: 100%;
    height: auto;
}

.main_heading_section 
{
    width: 100%;
    height: auto;
}


.main_heading_area h1 
{
    font-size: 3em;
    margin: 0.2em;
    text-shadow: 0.04em 0.04em 0 rgba(0, 0, 0, .16);
    color: #2ea2cc;
    text-align: center;
    text-transform: capitalize;
}

.main_heading_area h1 span 
{
    color: black;
}

.main_heading_area h2 
{
    font-size: 1.4em;
    color: rgba(1,1,1,0.5);
    font-weight: normal;
    font-family: 'Dancing Script', cursive;
    text-align: center;
}

.cards_carousel_section
{
    width: 100%;
    height: auto;
}

.card_carousel_area
{
    width: 100%;
    height: 400px;
    margin: 50px auto;
 }

.slick-slider 
{
    height: 100%;
}

.slick-track
{
    height: 100%;
}

.slick-slide 
{
  width: 354px!important;
  margin: 0 10px;
}

.slick-list 
{
  margin: 0 -10px;
  height: 100%;
}

.slick-prev:before, .slick-next:before
{
    color: #2ea2cc!important;
    font-size: 25px;
}

.slick-prev {
    left: -38px!important;
}

.slick-next {
    right: -29px!important;
}


.best_sellers
{
    width: 100%;
    height: auto;
}

.popular_bike_helmets
{
    width: 100%;
    height: auto;
}

.popular_bike_accessories
{
    width: 100%;
    height: auto;
}

.product_section
{
    width: 100%;
    height: auto;
    /* background-color: #efefef; */
}

.latest_product_section
{
    width: 100%;
    height: auto;
}

.most_viewed_section
{
    width: 100%;
    height: auto;
}

.top_rated_section
{
    width: 100%;
    height: auto;
}

.product_heading_section
{
    width: 100%;
    height: auto;
    padding: 20px 0px;
}

.product_heading_section h1 
{
    font-size: 1.5em;
    margin: 0.2em;
    text-shadow: 0.04em 0.04em 0 rgba(0, 0, 0, .16);
    color: #2ea2cc;
    text-transform: capitalize;
    position: relative;
}

.product_heading_section h1::after
{
    content: '';
    position: absolute;
    bottom: -9px;
    left: 2px;
    width: 100%;
    height: 2px;
    background-color: #efefef;
}

.product_heading_section h1::before
{
    content: '';
    position: absolute;
    bottom: -9px;
    left: 2px;
    width: 20%;
    height: 2px;
    background-color: #2ea2cc;
    z-index: 10;

}

.product_heading_section h1 span 
{
    color: black;
}

