
.product_listing_wrapper
{
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.inner-sec-container
{
    max-width: 1180px;
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.product_listing_content_section
{
    width: 100%;
    height: auto;
}

.categories_section
{
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    padding: 15px 20px;
    border-radius: 5px;
}

.categories_heading
{
    color: #333;
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 22px;
}

.categories_list_section
{
    width: 100%;
    height: auto;
    margin-top: 25px;
}

.categories_list_section ul
{
    padding: 0;
    margin: 0;
}

.categories_list_area
{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.categories_list_area a
{
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #333;
}

.categories_list_icon
{
    font-size: 23px;
    color: #333;
    
}

.categories_list_area:hover
{
    cursor: pointer;
}

.categories_list_area:hover .categories_list_icon
{
    color: #2ea2cc;
}

.categories_list_area:hover a
{
    color: #2ea2cc;
}


.price_filter_area
{
    width: 100%;
    height: auto;
    margin-top: 40px;
}

.price_filter_heading
{
    color: #333;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
}

.price_filter_label
{
    color: #8d8d8d;
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 16px;
}

.filter_price
{
    color: #333;
    margin: 0;
    line-height: 1.2;
    font-size: 16px;
    margin-left: 15px;
    font-weight: 500;
}

.price_filter_area .css-187mznn-MuiSlider-root
{
    margin: 15px 0;
}

.filter_button_section
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.reset_filter_btn
{
    border: none;
    padding: 4px 27px;
    font-size: 15px;
    background-color: #333;
    color: white;
    border-radius: 3px;
    font-weight: 600;
}

/* .reset_filter_btn:hover
{
    background-color: #2ea2cc;
} */

.filter_btn
{
    border: none;
    padding: 4px 27px;
    font-size: 15px;
    background-color: #2ea2cc;
    color: white;
    border-radius: 3px;
    font-weight: 600;
}

.categories_content_section
{
    width: 100%;
    height: auto;
    padding: 15px 0;
}

.categories_content_header_section
{
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
}

.showing_result_area
{
    width: 33.33%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.showing_result_area p
{
    margin: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.sorting_area
{
    width: 33.33%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.list_grid_view_section
{
    width: 33.33%;
    height: auto;
}

.grid_icon
{
    font-size: 18px;
    cursor: pointer;
}

.list_icon
{
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
}

#sorting_dropdown
{
    width: 60%;
    font-size: 14px;   
    border-radius: 4px;
    padding: 5px 5px;
    margin: 0;
    cursor: pointer;
}

#sorting_dropdown:focus-visible
{
    outline: 1px solid #2ea2cc;
    border: 1px solid #2ea2cc;
}

.categories_content_area
{
    width: 100%;
    height: auto;
}

.active
{
    background-color: #333;
    color: white;
    padding: 4px;
    width: 25px;
    height: 25px;
}

.catergories_list
{
    width: 100%;
    height: 100px;
    background-color: lightcoral;
}

.accordion-flush .accordion-item
{
    background-color: transparent;
    border: 0;
}

.accordion-header .accordion-button
{
    background-color: transparent;
    padding: 10px;
    font-weight: 600;
}

.accordion-button:not(.collapsed)
{
    color: #2ea2cc!important;
}

.accordion-header .accordion-button:focus
{
    box-shadow: none;
}

.accordion-button::after
{
    width: 1rem!important;
    height: 1rem!important;
    background-size: auto!important;
}

.accordion-body
{
    padding: 10px!important;
}

.accordion-body ul
{
    padding-left: 12px;
}

.accordion-body ul li
{
    color: #464646;
    font-size: 13px;
    margin: 12px 0;
    font-weight: 600;
}

.accordion-body ul li:hover
{
    cursor: pointer;
    color: #2ea2cc;
}

.pagination_section
{
    width: 100%;
    height: auto;
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination_area
{
    width: fit-content;
    height: auto;
    
}