.navList_section{
    width: 80vw;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    border-top: 3px solid #2ea2cc;
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    z-index: 10000;
    border-radius: 6px;
    overflow: hidden;
}

.categories_section
{
    width: 26%;
    height: auto;
    background-color: white;
    padding: 20px;
}


.categories_section .categories_list
{
    color: #333;
    display: block;
    padding: 12px 20px;
}

.categories_section .categories_list li 
{
    color: #333;
    list-style: disc;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 0;
}

.data_section{
    width: 74%;
    height: auto;
    background-color: whitesmoke;
    padding: 20px;
}

.data_card_section
{
    width: 100%;
    height: 275px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.categories_section_heading
{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
    color: #333;
}

.image_section
{
    width: 100%;
    height: 160px;
}

.image_section img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hide{
    display: none;
}


.data_card_content_section
{
    width: 100%;
    height: 115px;
    background-color: #e8e8e8;
    padding: 10px;
}
.data_card_content_section h4
{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin: 10px 0;
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.data_card_content_section p
{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0;
    color: #2ea2cc;
}


