.breadcrum_section
{
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    padding: 15px;
}

.inner_sec_container
{
    max-width: 1180px;
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.breadcrum_heading
{
    font-size: 14px;
    color: #555;
    margin: 0;
}

.breadcrum_heading a
{
    text-decoration: none;
}

.home_icon
{
    color: #555;
    transition: color .3s;
    font-size: 20px;
    font-size: 18px;
    margin-bottom: 3px;
}

.home_icon:hover
{
    color: #2ea2cc;
}