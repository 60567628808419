
.about_us_wrapper
{
    width: 100%;
    height: auto;
}

.about_us_parent_section
{
    width: 100%;
    height: auto;
}

.about_us_section
{
    width: 100%;
    height: auto;
}

.about_us_img_section
{
    width: 100%;
    height: 400px;
}

.about_us_img_section img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about_us_short_desc_section
{
    width: 100%;
    height: 100%;
}

.about_us_short_desc_section h2
{
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.about_us_short_desc_section p
{
    font-size: 15px;
    color: #909295;
    line-height: 27px;
}

.about_us_detai_desc_section
{
    width: 100%;
    height: auto;
}

.about_us_detai_desc_section p
{
    font-size: 15px;
    color: #909295;
    text-align: center;
    line-height: 27px;
}