
.social_content_section
{
    width: 100%;
    height: auto;
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.social_links_area
{
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social_links_area:hover
{
    background-color: #2ea2cc;
    color: white;
    cursor: pointer;

}
