
.card_card_section
{
    width: 100%;
    height: 70vh;
    padding: 15px;
    overflow: auto;
}

.cart_card_area
{
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 15px;
    background-color: whitesmoke;
    margin-top: 10px;
}

.card_card_section::-webkit-scrollbar 
{
    width: 6px;
}

.card_card_section::-webkit-scrollbar-track 
{
    background: transparent; 
}

.card_card_section::-webkit-scrollbar-thumb 
{
    background: #2ea2cc; 
    border-radius:10px;
}

.card_card_section::-webkit-scrollbar-thumb:hover 
{    
    background: #2ea2cc; 
} 

.cart_card_img_area
{
    width: 100%;
    height: 80px;
}

.cart_card_img_area img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cart_card_desc
{
    width: 100%;
    height: 80px;
}

.cart_card_desc h5
{
    font-size: 17px;
    color: #333;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}



.cart_card_remove
{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_icon
{
    font-size: 22px;
    color: grey;
}

.delete_icon:hover
{
    color: #2ea2cc;
    cursor: pointer;
}

.cart_card_price_section 
{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart_card_price_section p
{
    font-size: 15px;
    color: #2ea2cc;
    font-weight: bold;
    margin: 0;
}

.cart_card_price_section p span
{
    font-size: 15px;
    color: #333;
    font-weight: bold;
    margin: 0;
}
.counter_section
{
    padding: 0px;
    border-radius: 10px;
    width: fit-content;
    height: auto;
}
.counter
{
    padding: 0 7px;
    font-size: 18px;
    font-weight: bold;
    display: inline;
    border: none;
    background: transparent;
}

.counter_section .counter span
{
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}
  
.card_card_section .empty_list_section
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_card_section .empty_list_section h3
{
    font-size: 28px;
    color: #333;
    font-weight: 600;
}